var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"channel-mob"},[_c('mob-header',{attrs:{"interaction":false,"status":"info"}}),_c('mob-dropdown',{attrs:{"banner":_vm.channelData.channelKv,"title":_vm.channelData.channelName,"context":_vm.channelData.channelIntroduction}}),_c('div',{staticClass:"main"},[(
        _vm.channelData.haveAlreadyStarted
          ? _vm.channelData.haveAlreadyStarted.length > 0
          : ''
      )?_c('div',{staticClass:"now-live"},[_vm._m(0),_vm._l((_vm.channelData.haveAlreadyStarted),function(item,i){return _c('mob-video',{key:i,attrs:{"data":item},on:{"refDataList":function($event){return _vm.getChannelInfo()}}})})],2):_vm._e(),(
        _vm.channelData.haveNotStarted
          ? _vm.channelData.haveNotStarted.length > 0
          : ''
      )?_c('div',{staticClass:"wait-live"},[_vm._m(1),_vm._l((_vm.channelData.haveNotStarted),function(item,i){return _c('mob-video',{key:i,attrs:{"data":item},on:{"refDataList":function($event){return _vm.getChannelInfo()}}})})],2):_vm._e(),(
        _vm.channelData.bunchPlanting ? _vm.channelData.bunchPlanting.length > 0 : ''
      )?_c('div',{staticClass:"end-live"},[_vm._m(2),_vm._l((_vm.channelData.bunchPlanting),function(item,i){return _c('mob-video',{key:i,attrs:{"data":item},on:{"refDataList":function($event){return _vm.getChannelInfo()}}})})],2):_vm._e()])],1)}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"title"},[_c('div',{staticClass:"img-box"},[_c('img',{attrs:{"src":require("../../assets/nowLive1.png"),"alt":""}})]),_c('p',[_vm._v("正在直播")])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"title"},[_c('div',{staticClass:"img-box"},[_c('img',{attrs:{"src":require("../../assets/start1.png"),"alt":""}})]),_c('p',[_vm._v("即将开始")])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"title"},[_c('div',{staticClass:"img-box"},[_c('img',{attrs:{"src":require("../../assets/review.png"),"alt":""}})]),_c('p',[_vm._v("直播回顾")])])}]

export { render, staticRenderFns }