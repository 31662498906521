<template>
  <div class="page-view">
    <channel-mob v-if="isMobile"></channel-mob>
    <channel-pc v-else></channel-pc>
  </div>
</template>

<script>
import ChannelPc from "./channel-pc.vue";
import ChannelMob from "./channel-mob.vue";
import { channelPv } from "../../api/channel";
export default {
  components: {
    ChannelPc,
    ChannelMob,
  },
  data() {
    return {
      isMobile: this.$isMobile(),
    };
  },
  created() {
    this.getChannelPv();
  },
  methods: {
    getChannelPv() {
      channelPv({
        id: this.$route.params.id,
      }).then();
    },
  },
};
</script>
