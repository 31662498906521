<template>
  <div class="channel-bar">
    <img :src="banner" alt="" />
    <div class="title">{{ channel + "频道" }}</div>
    <div class="context">
      <div class="title">
        {{ title + "频道简介" }}
      </div>
      <div class="txt">
        {{ context }}
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    channel: {
      type: String,
      require: true,
    },
    title: {
      type: String,
      require: true,
    },
    context: {
      type: String,
      require: true,
    },
    banner: {
      type: String,
      require: true,
    },
  },
};
</script>

<style lang="scss" scoped>
.channel-bar {
  width: 1200px;
  margin: auto;
  height: 345px;
  overflow: hidden;
  position: relative;
  img {
    width: 100%;
  }
  .title {
    position: absolute;
    left: 28px;
    bottom: 38px;
    color: #fff;
    font-weight: 800;
    font-size: 34px;
  }
  .context {
    width: 617px;
    height: 174px;
    background-color: rgba($color: #f6f0f0, $alpha: 0.8);
    position: absolute;
    bottom: 33px;
    right: 36px;
    box-sizing: border-box;
    padding: 30px;
    .title {
      font-size: 16px;
      margin-bottom: 18px;
      color: #000;
      font-weight: 800;
      position: unset;
    }
    .txt {
      font-size: 14px;
      color: rgb(48, 48, 48);
      text-align: justify;
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 4;
      overflow: hidden;
    }
  }
}
</style>
