<template>
  <div class="channel-pc">
    <pc-header></pc-header>
    <pc-channel-bar
      :banner="channelData.channelKv"
      :title="channelData.channelName"
      :context="channelData.channelIntroduction"
      :channel="channelData.channelName"
    ></pc-channel-bar>
    <div class="main">
      <div
        class="now-live"
        v-if="
          channelData.haveAlreadyStarted
            ? channelData.haveAlreadyStarted.length > 0
            : ''
        "
      >
        <div class="title">
          <div class="img-box">
            <img src="../../assets/nowLive1.png" alt="" />
          </div>
          <p>正在直播</p>
        </div>
        <div class="wrap">
          <pc-video
            v-for="(item, i) in channelData.haveAlreadyStarted"
            :key="i"
            :data="item"
            @refDataList="getChannelInfo()"
          ></pc-video>
        </div>
      </div>
      <div
        class="wait-live"
        v-if="
          channelData.haveNotStarted
            ? channelData.haveNotStarted.length > 0
            : ''
        "
      >
        <div class="title">
          <div class="img-box">
            <img src="../../assets/start1.png" alt="" />
          </div>
          <p>即将开始</p>
        </div>
        <div class="wrap">
          <pc-video
            v-for="(item, i) in channelData.haveNotStarted"
            :key="i"
            :data="item"
            @refDataList="getChannelInfo()"
          ></pc-video>
        </div>
      </div>
      <div
        class="end-live"
        v-if="
          channelData.bunchPlanting ? channelData.bunchPlanting.length > 0 : ''
        "
      >
        <div class="title">
          <div class="img-box">
            <img src="../../assets/review.png" alt="" />
          </div>
          <p>直播回顾</p>
        </div>
        <div class="wrap">
          <pc-video
            v-for="(item, i) in channelData.bunchPlanting"
            :key="i"
            :data="item"
            @refDataList="getChannelInfo()"
          ></pc-video>
        </div>
      </div>
    </div>
    <pc-footer></pc-footer>
  </div>
</template>

<script>
import PcHeader from "../../components/pc-header.vue";
import PcFooter from "../../components/pc-footer.vue";
import PcChannelBar from "../../components/pc-channel-bar.vue";
import PcVideo from "../../components/pc-video.vue";
import { channelInfo } from "../../api/channel";
export default {
  components: {
    PcHeader,
    PcFooter,
    PcChannelBar,
    PcVideo,
  },
  metaInfo() {
    return {
      title: this.channelData.channelName + "频道",
    };
  },
  data() {
    return {
      id: this.$route.params.id,
      channelData: {},
    };
  },
  created() {
    this.getChannelInfo();
  },
  methods: {
    // 查询频道数据
    getChannelInfo() {
      channelInfo({
        id: this.id,
      }).then((res) => {
        if (res.code == 0) {
          this.channelData = res.data;
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
p {
  margin: 0;
}
.channel-pc {
  height: 100%;
  background: url("../../assets/pcbg.png") 0 0 no-repeat;
  background-size: 100% auto;
  .channel-bar {
    margin-top: 40px;
  }
  .main {
    padding: 90px 0;
    width: 1200px;
    margin: 0 auto;
    .title {
      display: flex;
      margin: 20px 0;
      .img-box {
        width: 30px;
        height: 30px;
        img {
          width: 100%;
          display: block;
        }
      }
      p {
        font-size: 20px;
        font-weight: bold;
        padding-left: 15px;
        color: #999;
      }
    }
    .wrap {
      display: flex;
      flex-wrap: wrap;
      .pc-video {
        margin-bottom: 35px;
      }
      .pc-video:nth-child(3n-1) {
        margin: 0 15px 35px;
      }
    }
    .now-live .title p {
      color: #49b67b;
    }
    .wait-live .title p {
      color: #1872bb;
    }
  }
}
</style>
