import request from "@/utils/request";

// 频道信息
export function channelInfo(params) {
  return request({
    url: "/qd/HomePage/particularsLiveChannel",
    method: "get",
    params,
  });
}

// 添加频道点击量
export function channelPv(params) {
  return request({
    url: "qd/HomePage/addHits",
    method: "get",
    params,
  });
}
