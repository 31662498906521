<template>
  <div class="channel-mob">
    <mob-header :interaction="false" status="info"></mob-header>
    <mob-dropdown
      :banner="channelData.channelKv"
      :title="channelData.channelName"
      :context="channelData.channelIntroduction"
    ></mob-dropdown>
    <div class="main">
      <div
        class="now-live"
        v-if="
          channelData.haveAlreadyStarted
            ? channelData.haveAlreadyStarted.length > 0
            : ''
        "
      >
        <div class="title">
          <div class="img-box">
            <img src="../../assets/nowLive1.png" alt="" />
          </div>
          <p>正在直播</p>
        </div>
        <mob-video
          v-for="(item, i) in channelData.haveAlreadyStarted"
          :key="i"
          :data="item"
          @refDataList="getChannelInfo()"
        ></mob-video>
      </div>
      <div
        class="wait-live"
        v-if="
          channelData.haveNotStarted
            ? channelData.haveNotStarted.length > 0
            : ''
        "
      >
        <div class="title">
          <div class="img-box">
            <img src="../../assets/start1.png" alt="" />
          </div>
          <p>即将开始</p>
        </div>
        <mob-video
          v-for="(item, i) in channelData.haveNotStarted"
          :key="i"
          :data="item"
          @refDataList="getChannelInfo()"
        ></mob-video>
      </div>
      <div
        class="end-live"
        v-if="
          channelData.bunchPlanting ? channelData.bunchPlanting.length > 0 : ''
        "
      >
        <div class="title">
          <div class="img-box">
            <img src="../../assets/review.png" alt="" />
          </div>
          <p>直播回顾</p>
        </div>
        <mob-video
          v-for="(item, i) in channelData.bunchPlanting"
          :key="i"
          :data="item"
          @refDataList="getChannelInfo()"
        ></mob-video>
      </div>
    </div>
  </div>
</template>

<script>
import MobHeader from "../../components/mob-header.vue";
import MobDropdown from "../../components/mob-dropdown.vue";
import MobVideo from "../../components/mob-video.vue";
import { channelInfo } from "../../api/channel";
import wechat from "../../utils/wxShare";
import { wechatConfig } from "../../api/user";
export default {
  metaInfo() {
    return {
      title: this.channelData.channelName + "频道",
    };
  },
  components: {
    MobHeader,
    MobDropdown,
    MobVideo,
  },
  data() {
    return {
      id: this.$route.params.id,
      channelData: {},
    };
  },
  created() {
    this.getChannelInfo();
    this.getWechatConfig();
  },
  methods: {
    getWechatConfig() {
      wechatConfig({
        url: location.href,
      }).then((res) => {
        if (res.code == 0) {
          this.wxConfigData = res.data;
          wechat.share(
            this.wxConfigData,
            "因美纳在线讲堂",
            "因美纳在线讲堂",
            this.$store.state.url,
            location.href
          );
        }
      });
    },
    // 查询频道数据
    getChannelInfo() {
      channelInfo({
        id: this.id,
      }).then((res) => {
        if (res.code == 0) {
          this.channelData = res.data;
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
p {
  margin: 0;
}
.channel-mob {
  height: 100%;
  background: url("../../assets/mobbgsmall.png") 0 0 no-repeat;
  background-size: 100% auto;
  .main {
    padding: 0 0.1rem 0.9rem;
    .title {
      display: flex;
      margin: 0.2rem 0 0.1rem;
      .img-box {
        width: 0.2rem;
        height: 0.2rem;
        img {
          width: 100%;
          display: block;
        }
      }
      p {
        font-size: 0.14rem;
        font-weight: bold;
        padding-left: 0.15rem;
        color: #999;
      }
    }
    .now-live .title p {
      color: #49b67b;
    }
    .wait-live .title p {
      color: #1872bb;
    }
  }
}
</style>
