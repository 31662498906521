<template>
  <div class="dropdown-box" :style="`width: ${width}`">
    <div class="top">
      <img :src="banner" alt="" />
      <div class="title">{{ title + "频道" }}</div>
    </div>
    <div class="txt-box">
      <div :class="['txt', flag ? 'hidden3' : '']" ref="txtBox">
        <p ref="txtlength">{{ context }}</p>
      </div>
      <div class="dropDown_btn">
        <div
          class="btn"
          :class="[isActive ? 'turn' : '', clickShow ? '' : 'opcity0']"
          @click="infoOpen"
        ></div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    width: {
      type: String,
      default: "95%",
    },
    banner: {
      type: String,
      require: true,
    },
    title: {
      type: String,
      require: true,
    },
    context: {
      type: String,
      require: true,
    },
  },
  data() {
    return {
      isActive: false,
      txtHeight: "",
      clickShow: false,
      flag: true,
    };
  },
  watch: {
    context() {
      setTimeout(() => {
        this.getClickShow();
      }, 200);
    },
  },
  updated() {
    // console.log(this.$refs.txtlength.scrollHeight)
    // console.log(this.$refs.txtlength.clientHeight)
    if (this.$refs.txtBox.scrollHeight > this.$refs.txtBox.clientHeight) {
      // console.log(1)
      this.clickShow = true;
    }
  },
  // mounted() {
  //   console.log(this.context);
  //   if (this.context) {
  //     console.log(this.$refs.txtlength.offsetHeight);
  //     console.log(this.$refs.txtBox.offsetHeight);
  //     setTimeout(() => {
  //       console.log(this.$refs.txtlength.offsetHeight);
  //       console.log(this.$refs.txtBox.offsetHeight);
  //     }, 200);
  //   }
  // },
  methods: {
    infoOpen() {
      if (this.flag) {
        this.flag = false;
        this.isActive = true;
      } else {
        this.flag = true;
        this.isActive = false;
      }

      // if (
      //   this.$refs.txtlength.offsetHeight > this.$refs.txtBox.offsetHeight ||
      //   this.isActive
      // ) {
      //   var paddingHeight =
      //     0.15 *
      //     parseInt(document.documentElement.style.fontSize.split("px")[0]);
      //   this.isActive = !this.isActive;
      //   this.txtHeight =
      //     paddingHeight + this.$refs.txtlength.offsetHeight + "px";
      // }
    },
    getClickShow() {
      // this.flag = false
      // console.log(this.$refs.txtlength.offsetHeight);
      // console.log(this.$refs.txtBox.offsetHeight);
      // if (this.$refs.txtlength.offsetHeight > this.$refs.txtBox.offsetHeight) {
      //   this.clickShow = true;
      // } else {
      //   this.clickShow = false;
      // }
    },
  },
};
</script>

<style lang="scss" scoped>
.dropdown-box {
  margin: auto;
  // margin-top: .5rem;
  background: #fff;
  border-radius: 0.05rem;
  overflow: hidden;
}
p {
  margin: 0;
}
.top {
  padding: 0;
  height: 1.01rem;
  overflow: hidden;
  position: relative;
  img {
    width: 100%;
    // height: 100%;
    display: block;
  }
  .title {
    position: absolute;
    left: 0.16rem;
    bottom: 0.16rem;
    font-size: 0.16rem;
    font-weight: 900;
    color: #fff;
  }
}
.txt-box {
  width: 100%;
  .txt {
    padding: 0.15rem;
    transition: all 1s;
    padding-bottom: 0;
    margin-bottom: 0.15rem;
    // text-align: justify;
    font-size: 0.14rem;
    color: #999;
    // height: 0.7rem;
    // height: 0.6rem;
    overflow: hidden;
    // box-sizing: border-box;
  }
  .open {
    height: 3rem !important;
  }
}
.dropDown_btn {
  display: flex;
  justify-content: center;
  text-align: center;
  // height: .3rem;
  .btn {
    width: 0;
    height: 0;
    border: 0.06rem solid #fff;
    border-top-color: #999;
    // border-left: 0.08rem solid #fff;
    // border-top: 0.08rem solid #999;
    // border-right: 0.08rem solid #fff;
    // margin: auto;
    // margin-top: 0;
    // // transition: all 1s;
    // margin-bottom: 0.2rem;
    // transform: rotate(-45deg);
  }
  .turn {
    // transform: rotateY(180deg);
    border-top-color: #fff;
    border-bottom-color: #999;
    margin-bottom: 0.06rem;
  }
  .opcity0 {
    opacity: 0;
  }
  .opcity1 {
    opacity: 1;
  }
}
</style>
